<template>
  <div>
    <b-loading :active="loading" />
    <div class="table-title">
      Rapport du {{since | moment('l')}} au {{until | moment('l')}}
    </div>
    <table class="opthime-table is-fullwidth" v-if="users">
      <thead>
        <tr>
          <th></th>
          <th v-for="code in codes" :key="code">
            <event-preview v-if="code != allEventCodes.EXTRA_TS" :event-type="code"><span></span></event-preview>
            <template v-else>
              <event-preview :event-type="allEventCodes.TS"><span></span></event-preview> - <event-preview :event-type="allEventCodes.RP"><span></span></event-preview>
            </template>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td><user-name :user="user" /></td>
          <td v-for="code in codes" :key="code">
            <template v-if="code != allEventCodes.EXTRA_TS">{{user.events_counts[code]}}</template>
            <template v-else>{{user.events_counts[allEventCodes.TS] - user.events_counts[allEventCodes.RP]}}</template>
            <span class="total" v-if="user.events_totals[code]">/ {{user.events_totals[code]}}</span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td :colspan="codes.length + 1">
            <div class="dates-picker">
              Dates :
              du
              <b-field>
                <b-datepicker v-model="editSince" />
              </b-field>
              au
              <b-field>
                <b-datepicker v-model="editUntil" />
              </b-field>
              <div class="buttons">
                <button type="button" class="button is-primary" @click="setDates()">Valider</button>
                <button type="button" class="button" @click="defaultDates()">Réinitialiser</button>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';

import eventCodes from "@/utils/event_codes";
import EventPreview from "@/components/EventPreview";
import UserName from "@/components/UserName";
import {date2str} from "@/utils/date";

export default {
  components: {EventPreview, UserName},
  data() {
    return {
      users: null,
      since: null,
      until: null,
      editSince: null,
      editUntil: null,
      loading: true
    }
  },
  computed: {
    allEventCodes: () => eventCodes,
    codes() {
      return [
        eventCodes.CP,
        eventCodes.CSS,
        eventCodes.CC,
        eventCodes.CO,
        eventCodes.CS,
        eventCodes.EXTRA_TS,
      ];
    }
  },
  methods: {
    async loadData() {
      this.loading = true;
      let {data} = await axios.get(
        `events_summary?since=${date2str(this.since)}&until=${date2str(this.until)}`
      );
      this.users = data;
      this.loading = false;
    },
    defaultDates() {
      let endOfCurrentQuarter = moment().endOf('quarter');
      if(endOfCurrentQuarter.diff(moment(), 'days') <= 7) {
        this.since = moment().startOf('quarter').toDate();
        this.until = endOfCurrentQuarter.toDate();
      }
      else {
        // previous quarter
        this.since = moment().subtract(1, 'quarter').startOf('quarter').toDate();
        this.until = moment().subtract(1, 'quarter').endOf('quarter').toDate();
      }
      this.editSince = this.since;
      this.editUntil = this.until;
      this.loadData();
    },
    setDates() {
      this.since = this.editSince;
      this.until = this.editUntil;
      this.loadData();
    }
  },
  created() {
    this.defaultDates();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/colors.scss";

.opthime-table {
  border-bottom: 4px solid $primary;

  thead th {
    padding: 0.5em;
    font-weight: 500;
    border: 0px;

    &:not(:first-child) {
      width: 140px;
      padding: 0.5em 0;
    }
  }

  td {
    padding: 0.5em;
  }
}

.dates-picker {
  display: flex;
  align-items: center;
  justify-content: center;

  .field {
    margin-bottom: 0;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  .buttons {
    margin-left: 1em;
  }
}

.total {
  font-size: 0.8em;
  color: #666;
  position: relative;
  top: 1px;
  left: 3px;
}

/deep/ .event-preview .preview {
  margin: 0;
}
</style>
