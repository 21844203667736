<template>
  <div>
    <b-loading :active="loading" />

    <template v-if="user">
      <h1 class="title main-title">
        <template v-if="isCurrentUser">Mes informations</template>
        <template v-else>Informations de {{user.first_name}} {{user.last_name}}</template>
      </h1>

      <div class="box base-box">
        <h1 class="title">Congés</h1>
        <div class="columns">
          <div class="column is-one-quarter">
            <div>
              <h2 class="subtitle">Résumé congés légaux</h2>
              <table class="user-summary">
                <tr>
                  <th></th>
                  <th v-for="(summary, year) in user.summaries" :key="'holidays' + year">
                    {{year}}
                  </th>
                </tr>
                <tr>
                  <td>Pris</td>
                  <td v-for="(summary, year) in user.summaries" :key="'taken-holidays-' + year">
                    {{summary.holidays.nb_taken}}
                  </td>
                </tr>
                <tr>
                  <td>Prévus</td>
                  <td v-for="(summary, year) in user.summaries" :key="'planned-holidays-' + year">
                    {{summary.holidays.nb_planned}}
                  </td>
                </tr>
                <tr>
                  <td>Restants</td>
                  <td v-for="(summary, year) in user.summaries" :key="'remaining-holidays-' + year">
                    {{summary.holidays.nb_remaining}}
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td v-for="(summary, year) in user.summaries" :key="'total-holidays-' + year">
                    {{summary.holidays.total}}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="column is-one-quarter" v-if="intern">
            <div>
              <h2 class="subtitle">Résumé congés scientifiques</h2>
              <table class="user-summary">
                <tr>
                  <th></th>
                  <th v-for="(summary, year) in user.summaries" :key="'scientific' + year">
                    {{year}}
                  </th>
                </tr>
                <tr>
                  <td>Pris</td>
                  <td v-for="(summary, year) in user.summaries" :key="'taken-scientific-' + year">
                    {{summary.scientific.nb_taken}}
                  </td>
                </tr>
                <tr>
                  <td>Prévus</td>
                  <td v-for="(summary, year) in user.summaries" :key="'planned-scientific-' + year">
                    {{summary.scientific.nb_planned}}
                  </td>
                </tr>
                <tr>
                  <td>Restants</td>
                  <td v-for="(summary, year) in user.summaries" :key="'remaining-holidays-' + year">
                    {{summary.scientific.nb_remaining}}
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td v-for="(summary, year) in user.summaries" :key="'total-holidays-' + year">
                    {{summary.scientific.total}}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="column">
            <h2 class="subtitle">Prochains congés</h2>
            <ul>
              <li v-for="holiday in futureHolidays" :key="holiday.id">
                <event-preview :event="holiday">
                  <event-dates :event="holiday" />
                </event-preview>
              </li>
            </ul>
            <p class="has-text-grey" v-if="futureHolidays.length == 0">Aucun congé prévu cette année.</p>

            <button v-if="isCurrentUser" class="button is-primary" @click="newHoliday()">
              <b-icon icon="plus" size="is-small" />
              <span>Ajouter</span>
            </button>
          </div>
        </div>
      </div>

      <!-- Duties -->

      <div class="box base-box">
        <h1 class="title">Gardes</h1>
        <div class="columns">
          <div class="column is-one-quarter">
            <div>
              <h2 class="subtitle">Résumé</h2>
              <table class="user-summary">
                <tr>
                  <th></th>
                  <th v-for="(summary, year) in user.summaries" :key="'duties' + year">
                    {{year}}
                  </th>
                </tr>
                <tr>
                  <td>Effectuées</td>
                  <td v-for="(summary, year) in user.summaries" :key="'performed-duties-' + year">
                    {{summary.duties.nb_performed}}
                  </td>
                </tr>
                <tr>
                  <td>Prévues</td>
                  <td v-for="(summary, year) in user.summaries" :key="'planned-duties-' + year">
                    {{summary.duties.nb_planned}}
                  </td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td v-for="(summary, year) in user.summaries" :key="'total-duties-' + year">
                    {{summary.duties.total}}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="column">
            <h2 class="subtitle">Prochaines gardes</h2>
            <ul>
              <li v-for="duty in futureDuties" :key="duty.id">
                <event-preview :event="duty">
                  <event-dates :event="duty" />
                </event-preview>
              </li>
            </ul>
            <p class="has-text-grey" v-if="futureDuties.length == 0">Aucune garde déjà planifiée.</p>
          </div>
          <!-- <div class="column"> TODO
            <h2 class="subtitle">Préférences</h2>
            <p class="info-text">Eviter de préférence les gardes aux dates suivantes :</p>
            <ul>
              <li>2/3/2020 - 8/3/2020</li>
              <li>2/3/2020 - 8/3/2020</li>
            </ul>
            <button v-if="isCurrentUser" class="button is-primary">
              <b-icon icon="plus" size="is-small" />
              <span>Ajouter</span>
            </button>
          </div> -->
        </div>
      </div>

      <hr>

      <year-calendar :events="events" />

      <b-modal :active.sync="activeModal" :width="600" trap-focus>
        <div class="box">
          <event-form @eventCreated="eventCreated" :allowed-codes="allowedCodes" />
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import moment from "moment";

import codes, {isDuty} from "@/utils/event_codes";
import groups from "@/utils/groups";
import EventForm from "@/components/EventForm";
import YearCalendar from "@/components/utils/YearCalendar";
import EventDates from "@/components/EventDates";
import EventPreview from "@/components/EventPreview";

export default {
  components: {
    EventForm,
    YearCalendar,
    EventDates,
    EventPreview
  },
  data() {
    return {
      curYear: null,
      activeModal: false,
      user: null,
      loading: true,
      holidayCodes: [codes.CP, codes.CSS, codes.CM, codes.CC, codes.CS],
      allowedCodes: null
    };
  },
  computed: {
    idUser() {
      return this.$route.params.idUser;
    },
    isCurrentUser() {
      return this.$store.state.currentUser.id == this.idUser;
    },
    events() {
      let data = []
      for(let evt of this.user.events) {
        let start = moment(evt.start_date.date);
        let end = moment(evt.end_date.date);
        let nbDays = end.diff(start, 'days');
        for(let i=0; i <= nbDays; i++){
          data.push({id: evt.id, type: evt.type, date: moment(start).add(i, 'days')});
        }
      }
      return data;
    },
    futureDuties() {
      return this.futureEvents(evt => isDuty(evt));
    },
    futureHolidays() {
      return this.futureEvents(evt => this.holidayCodes.includes(evt.type));
    },
    intern() {
      return this.user.group == groups.INTERN;
    }
  },
  watch: {
    idUser() {
      this.loadData();
    }
  },
  methods: {
    futureEvents(condition) {
      let events = this.user.events.filter(evt => condition(evt) && moment(evt.start_date.date) >= moment());
      events.sort(this.chronological_sort);
      return events;
    },
    async loadData() {
      this.loading = true;
      let res = await axios.get(`user/${this.idUser}?years=${this.curYear}&years=${this.curYear + 1}`);
      this.loading = false;
      this.user = res.data;
    },
    chronological_sort(a, b) {
      return (a.start_date.date < b.start_date.date) ? -1 : 1;
    },
    newHoliday() {
      this.allowedCodes = this.holidayCodes;
      this.activeModal = true;
    },
    eventCreated() {
      this.loadData();
    }
  },
  created() {
    this.curYear = new Date().getFullYear();
    this.loadData();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/colors.scss";
@import "~bulma/sass/utilities/mixins.sass";

h1.main-title {
  text-align: center;
  text-transform: uppercase;
  margin-top: -10px;
  font-size: 1.3rem;
}

h2.subtitle {
  margin-bottom: 0.75em;

  &:after {
    content: "";
    display: block;
    width: 50px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    margin-top: 0.2em;
  }
}

.info-text {
  margin-bottom: 0.5em;
}

.user-summary {
  min-width: 10em;

  td, th {
    padding: 0.3rem 1rem 0.3rem 0;
    vertical-align: middle;
  }

  td:first-child, th {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: normal;
  }

  td:not(:first-child) {
    font-weight: 500;
    text-align: center;
  }

  tr:first-child {
    border-bottom: 2px solid #ddd;
  }

  tr:last-child {
    border-top: 2px solid #ddd;
  }
}

li {
  list-style-type: none;
  list-style-position: inside;
  padding-bottom: 0.25em;
}

.button {
  margin-top: 1em;
}
</style>
