const codes = {
	CP: 'CP',
	// CM: 'CM',
	CSS: 'CSS',
  RP: 'RP',
	CC: 'CC',
	// CG: 'CG',
	// F: 'F',
	CO: 'CO',
  CS: 'CS',
  G1: 'G1',
  G2: 'G2',
  TS: 'TS',
  EXTRA_TS: 'EXTRA_TS' // special event only used in reports (== TS - RP)
};

export let codesMapping = {
	[codes.CP]: {code: "CP", desc: "Congé"},
	// [codes.CM]: {code: "CM", desc: "Congé maladie"},
	[codes.CSS]: {code: "CSS", desc: "Congé sans solde"},
	[codes.RP]: {code: "RP", desc: "Récupération prestations"},
	[codes.CC]: {code: "CC", desc: "Congé de circonstance"},
	// [codes.CG]: {code: "CG", desc: "Congrès"},
	// [codes.F]: {code: "F", desc: "Formation et EPU"},
	[codes.CO]: {code: "CO", desc: "Cours IFAPME et Barboux"},
	[codes.CS]: {code: "CS", desc: "Congé scientifique"},
	[codes.G1]: {code: "G1", desc: "Première garde"},
	[codes.G2]: {code: "G2", desc: "Deuxième garde"},
  [codes.TS]: {code: "TS", desc: "Jour de travail supplémentaire"},
};

export let dutyCodes = [codes.G1, codes.G2];

export function isDuty(event) {
  return dutyCodes.includes(event.type);
}

export function isInternal(event) {
  return event.type === codes.EXTRA_TS;
}

export default codes;
